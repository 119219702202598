import React, { useState } from "react";
import {
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Card,
  CardContent,
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  useTheme,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import QrCodeIcon from "@mui/icons-material/QrCode";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import UpdateIcon from "@mui/icons-material/Update";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EscaneoQR from "../assets/EscaneoQR.jpeg";
import CancionPedida from "../assets/Cancion pedida.png";
import VistadeCanciones from "../assets/vistadecanciones.png";
import TomaElControl from "../assets/TomaElControl.jpeg";
import Navbar from "../components/Navbar";
import DJModal from "../components/DJModal";

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [activeStep, setActiveStep] = useState(0);
  const [openDJModal, setOpenDJModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(""); // Estado para el plan seleccionado

  // Datos de los planes
  const plans = {
    monthly: { price: "S/.35", title: "Plan Mensual", subtitle: "Ideal para empezar y explorar." },
    yearly: { price: "S/.350", title: "Plan Anual", subtitle: "Ahorra S/.70 con este plan exclusivo." },
  };

  const features = [
    { text: "Vistas personalizadas de sugerencias de canciones.", icon: <CheckCircleIcon /> },
    { text: "Un QR personalizado único para tu evento.", icon: <QrCodeIcon /> },
    { text: "Datos detallados sobre las peticiones de canciones.", icon: <DataUsageIcon /> },
    { text: "Acceso a próximas actualizaciones.", icon: <UpdateIcon /> },
  ];

  const steps = [
    {
      title: "Escanea el código QR",
      description: "Tu público puede escanear el código QR único desde cualquier dispositivo.",
      image: EscaneoQR,
    },
    {
      title: "Envía tu canción",
      description: "El público puede solicitar canciones personalizadas directamente desde sus dispositivos.",
      image: CancionPedida,
    },
    {
      title: "Recibe las solicitudes",
      description: "El DJ recibe las solicitudes en tiempo real y puede decidir cuáles poner.",
      image: VistadeCanciones,
    },
    {
      title: "Toma el control",
      description: "Como DJ, tú decides qué canciones reproducir, manteniendo el ambiente ideal.",
      image: TomaElControl,
    },
  ];

  const handleToggle = (_, value) => {
    if (value !== null) setBillingCycle(value);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => (prevStep + 1) % steps.length);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => (prevStep - 1 + steps.length) % steps.length);
  };

  const handleOpenModal = () => {
    const selectedPlanInfo = `${plans[billingCycle].title} - ${plans[billingCycle].price}`;
    setSelectedPlan(selectedPlanInfo); // Guardar el plan seleccionado
    setOpenDJModal(true); // Abrir el modal
  };

  const theme = useTheme();

  return (
    <>
      <Navbar />
      <Box
        sx={{
          textAlign: "center",
          padding: 4,
          backgroundColor: theme.palette.primary.main,
          color: "white",
          minHeight: "100vh",
          mt: { xs: 7, sm: 8 },
        }}
      >
        {/* Título principal */}
        <Typography variant="h3" gutterBottom sx={{ fontWeight: "bold" }}>
          Lleva tu fiesta al siguiente nivel
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Únete a <strong>djponla.com</strong> y haz que tu show destaque.
        </Typography>

{/* Contenedor del slider */}
<Box
  sx={{
    mt: 4,
    position: "relative",
    maxWidth: { xs: "90%", md: "60%" }, // Ajusta el ancho según el tamaño de la pantalla
    margin: "auto", // Centra horizontalmente
  }}
>
  {/* Stepper */}
  <Stepper
    activeStep={activeStep}
    alternativeLabel
    sx={{
      mb: 3,
      "& .MuiStepIcon-root": {
        color: "#444",
        borderRadius: "50%",
      },
      "& .MuiStepIcon-root.Mui-active": {
        color: "#ff5722",
      },
      "& .MuiStepIcon-root.Mui-completed": {
        color: "#4caf50",
      },
    }}
  >
    {steps.map((step, index) => (
      <Step key={index}>
        <StepLabel>
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              display: {
                xs: "none",
                md: "block",
              },
            }}
          >
            {step.title}
          </Typography>
        </StepLabel>
      </Step>
    ))}
  </Stepper>

  {/* Card del contenido */}
  <Card
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#222",
      color: "white",
      padding: 3,
      position: "relative",
    }}
  >
    <Box
      component="img"
      src={steps[activeStep].image}
      alt={steps[activeStep].title}
      sx={{
        maxWidth: "100%",
        maxHeight: 380,
        objectFit: "contain",
        mb: 2,
        borderRadius: "14px",
      }}
    />
    <Typography variant="h5" sx={{ fontWeight: "bold", color: "#ff5722" }}>
      {steps[activeStep].title}
    </Typography>
    <Typography variant="body1" sx={{ mt: 1 }}>
      {steps[activeStep].description}
    </Typography>
  </Card>

  {/* Botones del slider */}
  <IconButton
    onClick={handleBack}
    sx={{
      position: "absolute",
      left: { xs: 8, sm: 50 },
      top: "50%",
      transform: "translateY(-50%)",
      color: "white",
      zIndex: 1,
    }}
  >
    <ArrowBackIosNewIcon />
  </IconButton>
  <IconButton
    onClick={handleNext}
    sx={{
      position: "absolute",
      right: { xs: 8, sm: 50 },
      top: "50%",
      transform: "translateY(-50%)",
      color: "white",
      zIndex: 1,
    }}
  >
    <ArrowForwardIosIcon />
  </IconButton>
</Box>

        {/* Alternador mensual/anual */}
        <ToggleButtonGroup
          value={billingCycle}
          exclusive
          onChange={handleToggle}
          sx={{
            marginTop: 4,
            marginBottom: 3,
            border: "1px solid #fff",
            borderRadius: "20px",
            backgroundColor: "#444",
          }}
        >
          <ToggleButton value="monthly">Mensual</ToggleButton>
          <ToggleButton value="yearly">Anual</ToggleButton>
        </ToggleButtonGroup>

        {/* Tarjeta de precio */}
        <Card
          sx={{
            maxWidth: 400,
            margin: "0 auto",
            padding: 3,
            boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
            borderRadius: "16px",
            backgroundColor: "#222",
            color: "white",
            mt: 4,
          }}
        >
          <CardContent>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              {plans[billingCycle].title}
            </Typography>
            <Typography variant="h5" sx={{ color: "#ff5722", fontWeight: "bold" }}>
              {plans[billingCycle].price}
            </Typography>
            <Typography variant="subtitle1">{plans[billingCycle].subtitle}</Typography>

            <Divider sx={{ marginY: 2, backgroundColor: "#444" }} />

            <List>
              {features.map((feature, index) => (
                <ListItem key={index} sx={{ paddingY: 0.5 }}>
                  <ListItemIcon sx={{ color: "#ff5722" }}>{feature.icon}</ListItemIcon>
                  <ListItemText primary={feature.text} />
                </ListItem>
              ))}
            </List>

            <Button
              variant="contained"
              onClick={handleOpenModal}
              fullWidth
              sx={{
                marginTop: 2,
                color: "#ffffff",
                backgroundColor: "#115beb",
                fontWeight: "bold",
              }}
            >
              Comprar suscripción
            </Button>
          </CardContent>
        </Card>

        {/* Modal con el precio enviado */}
        <DJModal
  open={openDJModal}
  onClose={() => setOpenDJModal(false)}
  selectedPrice={plans[billingCycle].price}
/>

        <Typography variant="body2" sx={{ marginTop: 3, color: "#888", fontStyle: "italic" }}>
          Cancela en cualquier momento. Sin compromisos.
        </Typography>
      </Box>
    </>
  );
};

export default Pricing;