import React from "react";
import { Box, Typography, Button } from "@mui/material";
import PreviewDJ from "./PreviewDJ";

const DJPreview = ({ formData, handleBack, handleNext }) => {
  // Asegurarse de que formData tenga valores completos
  if (!formData || !formData.phone || !formData.djName || !formData.preview) {
    // Si algún campo está vacío, podríamos mostrar un mensaje o no permitir continuar
    return (
      <Box textAlign="center">
        <Typography variant="h6" gutterBottom>
          Algunos campos aún no están completos. Por favor, revisa la información.
        </Typography>
        <Button variant="contained" onClick={handleBack}>
          Atrás
        </Button>
      </Box>
    );
  }

  return (
    <Box textAlign="center">
      <Typography variant="h6" gutterBottom>
        Vista previa
      </Typography>
      <PreviewDJ
        djName={formData.djName} // Nombre del DJ
        djPhoto={formData.preview} // Foto subida (previsualización)
        creatorName="DJ PONLA" // Puedes cambiar el texto si es dinámico
      />
      <Box mt={2}>
        <Button variant="outlined" sx={{ mr: 1, color:'#ffffff', border:'1px solid #ffffff' }} onClick={handleBack}>
          Atrás
        </Button>
        <Button variant="contained" onClick={handleNext}sx={{ backgroundColor:'#4caf50'}}>
          Continuar
        </Button>
      </Box>
    </Box>
  );
};

export default DJPreview;
