import { useState, useEffect } from 'react';
import { getDjBio } from '../api/airtable';

function useDjData(djName) {
  const [djData, setDjData] = useState(null);
  const [djId, setDjID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDjBio(djName)
      .then((data) => {
        setDjData(data.fields);
        setDjID(data.id)
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setLoading(false);
      });
  }, [djName]);

  return { djData,djId, loading, error };
}

export default useDjData;
