import React, { useState } from "react";
import { Box, Modal, Stepper, Step, StepLabel, Typography, IconButton } from "@mui/material";
import DJForm from "./DJForm";
import DJPreview from "./DJPreview";
import PaymentStep from "./PaymentStep";
import CloseIcon from "@mui/icons-material/Close";

const DJModal = ({ open, onClose, selectedPrice }) => {
  const [step, setStep] = useState(0); // Mantener el índice del paso actual
  const [formData, setFormData] = useState({
    djName: "",
    instagramLink: "",
    phone: "",
    email: "",
    photo: null,
    preview: null,
  });

  const [errors, setErrors] = useState({
    djName: "",
    instagramLink: "",
    phone: "",
    email: "",
    photo: "",
  });

  const handleNext = () => {
    const newErrors = {
      djName: formData.djName ? "" : "El nombre es obligatorio.",
      instagramLink: formData.instagramLink ? "" : "El link de Instagram es obligatorio.",
      phone: formData.phone ? "" : "El número de teléfono es obligatorio.",
      email: formData.email ? "" : "El correo electrónico es obligatorio.",
      photo: formData.photo ? "" : "La foto es obligatoria.",
    };

    setErrors(newErrors);

    // Verificación del valor del teléfono antes de realizar cualquier operación
    const phone = formData.phone ? String(formData.phone) : "";
    if (!newErrors.djName && !newErrors.instagramLink && !newErrors.phone && !newErrors.email && !newErrors.photo) {
      if (phone.slice(0, 2) === "+5") {
        setStep((prev) => prev + 1); // Avanzar al siguiente paso
      } else {
        setErrors({ ...newErrors, phone: "Te contactaremos al número que has proporcionado." });
        setStep((prev) => prev + 1); // Continuar con el siguiente paso si lo deseas
      }
    }
  };

  const handleBack = () => setStep((prev) => prev - 1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData({ ...formData, photo: file, preview: reader.result });
        setErrors({ ...errors, photo: "" });
      };
      reader.readAsDataURL(file);
    }
  };

  const steps = ["Información del DJ", "Vista previa", "Pago"];

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: "90%", maxWidth: 600, bgcolor: "background.paper", borderRadius: 3, boxShadow: 24, p: 4 }}>
        
        {/* Botón de cierre en la esquina superior derecha */}
        <IconButton 
          onClick={onClose} 
          sx={{ position: "absolute", top: 10, right: 10, color: "#C2C2C2" }}
        >
          <CloseIcon />
        </IconButton>

        <Stepper
          activeStep={step} // Cambiado para usar el índice del paso
          alternativeLabel
          sx={{
            mb: 3,
            "& .MuiStepIcon-root": {
              color: "#444",
              borderRadius: "50%",
            },
            "& .MuiStepIcon-root.Mui-active": {
              color: "#ff5722", // Color cuando el paso está activo
            },
            "& .MuiStepIcon-root.Mui-completed": {
              color: "#4caf50", // Color cuando el paso está completado
            },
            "& .MuiStepIcon-root.Mui-completed:before": {
              content: '"✔"', // Agregar el ícono de check cuando el paso esté completado
              fontSize: "1.5rem", // Ajustar el tamaño del ícono
              color: "#4caf50", // Color del check
            },
          }}
        >
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    display: {
                      xs: "none",
                      md: "block",
                    },
                  }}
                >
                  {step} {/* El nombre del paso aquí */}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {step === 0 && (
          <DJForm
            formData={formData}
            errors={errors}
            handleChange={handleChange}
            handleFileUpload={handleFileUpload}
            handleNext={handleNext}
          />
        )}

        {step === 1 && (
          <DJPreview
            formData={formData}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        )}

        {step === 2 && (
          <PaymentStep onClose={onClose} selectedPrice={selectedPrice} formData={formData} />
        )}
      </Box>
    </Modal>
  );
};

export default DJModal;
