import React from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Link as MuiLink,
  Avatar,
} from '@mui/material';
import Navbar from '../components/Navbar';
import InstagramIcon from '@mui/icons-material/Instagram';
import AbrahamImage from '../assets/Abraham.jpeg';
import RobertoImage from '../assets/Roberto.jpeg';
import { Link } from 'react-router-dom';

const teamMembers = [
  {
    name: 'Roberto Salazar',
    role: 'Founder and CEO',
    image: RobertoImage,
    linkedin: 'https://www.linkedin.com/in/roberto-salazarg/',
  },
  {
    name: 'Abraham Huacchillo',
    role: 'Founder and CTO',
    image: AbrahamImage,
    linkedin: 'https://www.linkedin.com/in/abraham-huacchillo/',
  },
];

const TeamMemberCard = ({ member }) => (
  <Card
    sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      overflow: 'hidden',
      alignItems: 'center',
      textAlign: 'center',
      transition: 'transform 0.3s',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: 6,
      },
      padding: 2,
    }}
  >
    <Avatar
      src={member.image}
      alt={`Foto de ${member.name}`}
      sx={{ width: 120, height: 120, mt: 2, mb: 2 }}
    />
    <CardContent sx={{ flexGrow: 1 }}>
      <MuiLink href={member.linkedin} target="_blank" underline="none" color="inherit">
        <Typography variant="h6" gutterBottom>
          {member.name}
        </Typography>
      </MuiLink>
      <Typography variant="body2" color="text.secondary">
        {member.role}
      </Typography>
    </CardContent>
  </Card>
);

const AboutUs = () => {
  const theme = useTheme();

  return (
    <>
      <Navbar />
      <Box sx={{ mt: { xs: 7, sm: 8 } }}>
        <Container maxWidth="lg">
          {/* Sección Hero */}
          <Box
            sx={{
              textAlign: 'center',
              py: 8,
              px: 2,
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
              borderRadius: '10%',
              mb: 5,
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              sx={{
                fontFamily: "'Concert One', cursive",
                textShadow: '2px 2px 4px rgba(0,0,0,0.6)',
              }}
            >
              Conoce DJ Ponla
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontStyle: 'italic',
                mb: 3,
              }}
            >
              ¡La fiesta no para y nosotros tampoco!
            </Typography>
            <Button
              variant="contained"
              href="https://www.instagram.com/djponla/"
              target="_blank"
              startIcon={<InstagramIcon />}
              sx={{
                borderRadius: 4,
                backgroundColor: '#115beb', // Nuevo color azul para el botón de Instagram
                color: theme.palette.common.white,
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#0e4da6', // Color más oscuro al pasar el mouse
                },
              }}
            >
              Síguenos en Instagram
            </Button>
          </Box>

          {/* Historia */}
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Nuestra Historia
            </Typography>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    p: 4,
                    borderRadius: 4,
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    El Comienzo
                  </Typography>
                  <Typography variant="body1">
                    Todo comenzó en una fiesta donde nos dimos cuenta de que la música es el alma
                    de cualquier celebración. Desde entonces, nos propusimos conectar a los mejores
                    DJs con quienes buscan momentos inolvidables. 🎶
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    p: 4,
                    borderRadius: 4,
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Nuestro Crecimiento
                  </Typography>
                  <Typography variant="body1">
                    Hemos crecido gracias a nuestra pasión por la música y el compromiso con la
                    diversión. Cada evento es una nueva oportunidad para crear recuerdos inolvidables.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Valores */}
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Nuestros Valores
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    background: `linear-gradient(135deg, ${theme.palette.primary.light} 30%, ${theme.palette.secondary.light} 90%)`,
                    p: 3,
                    borderRadius: 4,
                    boxShadow: 3,
                    color: theme.palette.common.white,
                    height: '100%',
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Pasión por la Música
                  </Typography>
                  <Typography>
                    Vivimos y respiramos música. Es nuestra motivación y lo que nos impulsa a seguir
                    innovando.
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card
                  sx={{
                    background: `linear-gradient(135deg, ${theme.palette.secondary.light} 30%, ${theme.palette.primary.light} 90%)`,
                    p: 3,
                    borderRadius: 4,
                    boxShadow: 3,
                    color: theme.palette.common.white,
                    height: '100%',
                  }}
                >
                  <Typography variant="h5" gutterBottom>
                    Innovación y Diversión
                  </Typography>
                  <Typography>
                    Siempre buscamos nuevas formas de hacer que cada evento sea único y memorable.
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Box>

          {/* Equipo */}
          <Box sx={{ my: 5 }}>
            <Typography variant="h4" align="center" gutterBottom>
              Nuestro Equipo
            </Typography>
            <Typography variant="body1" align="center" sx={{ mb: 3 }}>
              Conoce a los cerebros (y corazones) detrás de DJ Ponla.
            </Typography>
            <Grid container spacing={4}>
              {teamMembers.map((member, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <TeamMemberCard member={member} />
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Llamado a la acción final */}
          <Box
            sx={{
              textAlign: 'center',
              my: 5,
              py: 5,
              px: 2,
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              borderRadius: 4,
            }}
          >
            <Typography variant="h5" gutterBottom>
              Únete a nuestra comunidad y vive la experiencia DJ Ponla
            </Typography>
            <Button 
        component={Link} 
        to="https://api.whatsapp.com/send?phone=51945429543&text=%C2%A1Hola%20Roberto!%20Quiero%20ver%20una%20demo%20de%20la%20app%20para%20DJs%20%F0%9F%8E%B6%F0%9F%94%A5" 
        color="secondary" 
        variant="contained"
        target="_blank"
        sx={{
            borderRadius: 4,
            backgroundColor: '#115beb', // Nuevo color azul para el botón de Instagram
            color: theme.palette.common.white,
            fontWeight: 'bold',
            '&:hover': {
              backgroundColor: '#0e4da6', // Color más oscuro al pasar el mouse
            },
              }}
      >
        ¡Contáctanos!
      </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AboutUs;
