import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { getDJRecords } from '../api/airtable';

import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import ImageSlider from '../components/ImageSlider';
import Testimonials from '../components/Testimonials';
import Brands from '../components/Brands';
import DJsSection from '../components/DJsSection';
import Footer from '../components/Footer';

import DG from '../assets/djgiangi.jpg';
import DD from '../assets/djdiegopozo.jpeg';
import DH from '../assets/djhans.jpeg';
import DAM from '../assets/djadrianmogollon.jpg';
import DA from '../assets/djAndrew.jpg';
import image1 from '../assets/IMG1.png';
import image2 from '../assets/IMG2.png';
import image3 from '../assets/IMG3.png';
import logo1 from '../assets/alba.png';
import logo2 from '../assets/L-Ancora.png';
import logo3 from '../assets/dembow.png';
import logo4 from '../assets/Logo 4.png';
import logo5 from '../assets/Logo 5.png';
import logo6 from '../assets/LogoUDEP.png'

const Home = () => {
  const [djs, setDJs] = useState([]);

  // Cargar los DJs desde Airtable
  useEffect(() => {
    const fetchDJs = async () => {
      const records = await getDJRecords('DJs Bio');
      setDJs(records);
    };
    fetchDJs();
  }, []);

  const testimonials = [
    { name: 'DJ Giangi', text: '“Muy buena aplicación, se proyecta a que será algo revolucionario en la industria del DJ. ¡Recomendado!”', image: DG },
    { name: 'DJ Diego Pozo', text: '“Una app indispensable para los DJ, facilita la conexión con el público y te ayuda a comprenderlos para mantener siempre la pista de baile on fire”.', image: DD },
    { name: 'DJ Hans', text: '“Lo máximo la aplicación. Me ayudó a interactuar más con el público y que la juerga sea más chévere. Lo máximo. Recomendadísimo 🔥”', image: DH },
    { name: 'DJ Adrián Mogollon', text: '“Esta app está revolucionando la industria musical, tenemos una mejor conexión entre nuestros invitados y clientes en lo que quieren oír y bailar, sin duda alguna, una de las mejores ideas que se ha podido crear !!', image: DAM },
    { name: 'DJ Andrew', text: '“Conocer el gusto musical de cada persona será más fácil gracias a DJ Ponla, una app diseñada para nosotros a fin de que nuestra tocada sea un éxito al 100%”', image: DA },
  ];

  const slides = [
    {
      image: image1,
      title: '1. Conecta con tu público',
      description: 'Interacciona en tiempo real y descubre qué quiere escuchar tu audiencia.'
    },
    {
      image: image2,
      title: '2. Personaliza tus playlists',
      description: 'Crea experiencias únicas adaptadas a cada evento.'
    },
    {
      image: image3,
      title: '3. Analiza tus sesiones',
      description: 'Obtén estadísticas y mejora continuamente tus presentaciones.'
    }
  ];

  const brands = [
    { name: 'Alba', logo: logo1 },
    { name: 'L\'Ancora', logo: logo2 },
    { name: 'Dembow', logo: logo3 },
    {name: 'Manuel Agusto Eventos', logo:logo4},
    {name: 'La Casa de Milito', logo:logo5},
    {name: 'UDEP', logo:logo6}
  ];

  return (
    <>
      <Navbar />
      <Container maxWidth="lg" sx={{paddingTop:"80px"}}>
        <HeroSection />
        <Testimonials testimonials={testimonials} />
        <ImageSlider slides={slides} />
        <Brands brands={brands} />
        <DJsSection djs={djs} />
        <Footer />
      </Container>
    </>
  );
};

export default Home;
