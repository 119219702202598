import React from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const DJForm = ({ formData, errors, handleChange, handleFileUpload, handleNext }) => {
  const handleNextClick = () => {
    // Asegúrate de pasar un valor de teléfono válido
    handleNext({ phone: formData.phone });
  };

  return (
    <Box
      component="form"
      sx={{
        width: "100%",
        maxHeight: "80vh",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#121212",
        borderRadius: 2,
        p: 3,
        boxShadow: "0 4px 10px rgba(0,0,0,0.5)",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{ marginBottom: 2, color: "#ffffff", textAlign: "center" }}
      >
        Completa tu Información
      </Typography>

      {/* Campos de texto */}
      <Grid container spacing={2} sx={{ width: "100%" }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nombre de DJ"
            name="djName"
            value={formData.djName}
            onChange={handleChange}
            error={!!errors.djName}
            helperText={errors.djName}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#C2C2C2" },
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Link de Instagram"
            name="instagramLink"
            value={formData.instagramLink}
            onChange={handleChange}
            error={!!errors.instagramLink}
            helperText={errors.instagramLink}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#C2C2C2" },
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
      <PhoneInput
        country="pe"
        value={formData.phone}
        onChange={(phone) => handleChange({ target: { name: 'phone', value: phone } })}
        inputStyle={{
          width: '100%',
          backgroundColor: '#1E1E1E', // Fondo oscuro para el campo de entrada
          color: '#ffffff', // Texto blanco para el campo
          borderColor: '#C2C2C2',
          borderRadius: '4px',
          padding: '10px',
          paddingLeft: '50px', // Espaciado para icono de país
          transition: 'all 0.3s ease', // Transición suave
        }}
        buttonStyle={{
          backgroundColor: '#333',
          color: '#ffffff',
          borderRadius: '4px 0 0 4px',
          position: 'absolute',
          transition: 'background-color 0.3s ease, color 0.3s ease', // Transición en hover
        }}
        containerStyle={{
          position: 'relative',
          width: '100%',
        }}
        dropdownStyle={{
          backgroundColor: '#222',
          color: '#929292',
          border: '1px solid #444',
          borderRadius: '4px',
          transition: 'background-color 0.3s ease',
          zIndex: 9999, // Asegura que el dropdown quede por encima de otros elementos
        }}
        searchStyle={{
          backgroundColor: '#1E1E1E',
          color: '#ffffff',
          borderColor: '#C2C2C2',
          borderRadius: '4px',
          padding: '8px',
        }}
        highlightStyle={{
          backgroundColor: '#3c3c3c', // Fondo gris oscuro cuando el país está destacado
          color: '#ffffff', // Texto blanco
        }}
        error={!!errors.phone}
        onFocus={(e) => (e.target.style.borderColor = '#115beb')}
        onBlur={(e) => (e.target.style.borderColor = '#C2C2C2')}
        dropdownItemStyle={{
          backgroundColor: '#333', // Fondo oscuro para cada item
          color: '#ffffff', // Texto blanco
          padding: '10px', // Espaciado para los items
          borderBottom: '1px solid #444', // Separadores entre items
          transition: 'background-color 0.3s ease', // Transición suave al hacer hover
        }}
        dropdownItemHoverStyle={{
          backgroundColor: '#555', // Fondo más claro al pasar el cursor
          color: '#ffffff', // Asegura que el texto siga siendo blanco
        }}
      />
      {errors.phone && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
          {errors.phone}
        </Typography>
      )}
    </Grid>


        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Correo Electrónico"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            sx={{
              "& .MuiOutlinedInput-root": {
                color: "#ffffff",
                "& .MuiOutlinedInput-notchedOutline": { borderColor: "#C2C2C2" },
              },
            }}
          />
        </Grid>
      </Grid>

      {/* Botón para cargar foto */}
      <Button
        variant="contained"
        component="label"
        fullWidth
        sx={{
          mt: 2,
          mb: 2,
          backgroundColor: "#115beb",
          color: "#ffffff",
          fontWeight: "bold",
        }}
      >
        <PhotoCameraIcon sx={{ mr: 1 }} />
        Cargar Foto
        <input type="file" accept="image/*" hidden onChange={handleFileUpload} />
      </Button>

      {/* Previsualización de la foto */}
      {formData.photo && (
        <Box sx={{ textAlign: "center", my: 2 }}>
        <Typography variant="body2" sx={{ color: "green", fontWeight: "bold" }}>
          ¡Archivo cargado con éxito!
        </Typography>
        <Typography variant="body2" sx={{ color: "#555" }}>
          Archivo: {formData.photo.name}
        </Typography>
      </Box>
      )}


      {/* Botón siguiente */}
      <Button
        variant="contained"
        fullWidth
        onClick={handleNextClick}
        sx={{
          backgroundColor: "#4caf50",
          color: "#ffffff",
          fontWeight: "bold",
          "&:hover": { backgroundColor: "#0d47a1" },
        }}
      >
        Siguiente
      </Button>
    </Box>
  );
};

export default DJForm;
