// components/StyledComponents.js
import { styled } from '@mui/system';
import { Avatar, BottomNavigation, Typography, Paper, Box } from '@mui/material';

// Avatar Redondeado
export const RoundedAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(15),
  margin: '0 auto',
  borderRadius: theme.spacing(2),
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
}));

// Sección General
export const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'relative',
}));

// Tarjeta con Estilos Consistentes
export const Card = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  },
}));

// Barra de Navegación Inferior Flotante
export const FloatingNav = styled(BottomNavigation)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
  maxWidth: 600,
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
  borderRadius: theme.spacing(3),
  zIndex: 1000,
}));

// Texto con Degradado
export const GradientText = styled(Typography)(({ theme }) => ({
  background: `linear-gradient(90deg, #D32F2F, #B71C1C)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
}));
