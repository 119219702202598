import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Divider, CircularProgress, Fade, Alert } from "@mui/material";
import { UploadFile } from "@mui/icons-material";
import YAPE from "../assets/yape-plin.webp";
import { registerPayment, registerInternationalPayment } from "../api/airtable";
import { uploadFileToCloudinary } from "../api/cloudinary";
import { ContentCopy } from "@mui/icons-material";

const PaymentStep = ({ onClose, selectedPrice, formData }) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [uploading, setUploading] = useState(false);
  const [confirming, setConfirming] = useState(false); // Estado para bloquear el botón
  const [errorMessage, setErrorMessage] = useState("");
  const [isPeru, setIsPeru] = useState(false);
  const [showThankYouMessage, setShowThankYouMessage] = useState(false); // Estado para mensaje de agradecimiento
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [copyMessage, setCopyMessage] = useState("");

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyMessage("¡Número copiado al portapapeles!");
        setTimeout(() => setCopyMessage(""), 3000); // Limpia el mensaje después de 3 segundos
      })
      .catch(() => {
        setCopyMessage("Error al copiar, inténtalo de nuevo.");
        setTimeout(() => setCopyMessage(""), 3000);
      });
  };

  useEffect(() => {
    const phoneNumber = formData.phone || "";
    setIsPeru(phoneNumber.startsWith("+51") || phoneNumber.startsWith("51"));
  }, [formData.phone]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const uploadedFileUrl = await uploadFileToCloudinary(file);
        setFileUploaded(true);
        setFileName(file.name);
        formData.paymentFile = uploadedFileUrl;
        setErrorMessage("");
      } catch (error) {
        setErrorMessage("Hubo un error al subir el archivo.");
      } finally {
        setUploading(false);
      }
    }
  };

  const handleConfirm = async () => {
    if (isPeru && !fileUploaded) {
      setErrorMessage("Debe subir un comprobante antes de confirmar el pago.");
      return;
    }
  
    try {
      setConfirming(true); // Iniciar confirmación
      setErrorMessage("");
  
      // Subir foto si es necesario
      if (formData.photo && typeof formData.photo !== "string") {
        const uploadedPhotoUrl = await uploadFileToCloudinary(formData.photo);
        formData.photo = uploadedPhotoUrl;
      }
  
      const currentDate = new Date();
      const formattedPurchaseDate = currentDate.toLocaleDateString("es-PE");
  
      const paymentData = {
        djName: formData.djName,
        instagramLink: formData.instagramLink,
        phone: formData.phone,
        email: formData.email,
        plan: selectedPrice === "S/.35" ? "Mensual" : "Anual",
        price: selectedPrice,
        paymentDate: formattedPurchaseDate,
        photo: formData.photo,
      };
  
      if (isPeru) {
        paymentData.paymentFile = formData.paymentFile;
        await registerPayment(paymentData);
      } else {
        await registerInternationalPayment(paymentData);
      }
  
      // Mostrar mensaje de agradecimiento
      setShowThankYouMessage(true);
      setPaymentCompleted(true); // Marcar como pago completado
      setConfirming(false);
    } catch (error) {
      setConfirming(false);
      console.error("Error al confirmar el pago:", error);
      setErrorMessage("Ocurrió un error al registrar el pago. Inténtelo nuevamente.");
    }
  };
  

  return (
    <Box
      sx={{
        maxWidth: "600px",
        width: "100%",
        maxHeight: "70vh",
        overflowY: "auto",
        p: 3,
        backgroundColor: "#f5f5f5",
        borderRadius: 3,
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        animation: "fadeIn 0.8s ease-out",
      }}
    >
      {showThankYouMessage || paymentCompleted ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "#4caf50", mb: 2 }}>
            ¡Gracias por tu compra!
          </Typography>
          <Typography variant="body1" sx={{ color: "#555" }}>
            En minutos nos comunicaremos contigo con tus accesos.
          </Typography>
          <Button
            variant="contained"
            sx={{ mt: 3, fontWeight: "bold" }}
            onClick={onClose}
          >
            Cerrar
          </Button>
        </Box>
      ) : (
        <>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", color: "#333" }}>
            Realiza tu Pago
          </Typography>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ color: "#ff5722", fontWeight: "bold", textAlign: "center", mt: 2 }}
          >
            {`${selectedPrice}`}
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ color: "#555", mb: 2, textAlign: "center" }}>
            Completa tu pago para activar tu cuenta y disfrutar de todos los beneficios.
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Verificar si es número de Perú */}
          {isPeru ? (
            <>
              {/* Métodos de pago */}
              <Typography variant="body1" gutterBottom sx={{ fontWeight: "bold", mb: 2, color: "#333" }}>
                Elige tu método de pago:
              </Typography>
              {copyMessage && (
  <Alert
    severity="info"
    sx={{
      mb: 2,
      textAlign: "center",
      backgroundColor: "#e3f2fd",
      color: "#0d47a1",
    }}
  >
    {copyMessage}
  </Alert>
)}
              {/* Sección de Yape */}
              <Box
  sx={{
    textAlign: "left",
    mb: 3,
    p: 2,
    border: "1px solid #e0e0e0",
    borderRadius: 2,
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Asegura que el botón quede alineado
    transition: "all 0.3s",
    "&:hover": { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" },
  }}
>
  <Box display="flex" alignItems="center">
    <Box component="img" src={YAPE} alt="Logo de Yape" sx={{ width: 70, height: 45, mr: 2, borderRadius:'4px' }} />
    <Box>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
        Pago por Yape o Plin
      </Typography>
      <Typography variant="body2" sx={{ color: "#555" }}>
        Número: <strong>945429543</strong>
      </Typography>
    </Box>
  </Box>
  <Button
    onClick={() => copyToClipboard("945429543")}
    sx={{ minWidth: "auto", p: 0 }}
  >
    <ContentCopy fontSize="small" />
  </Button>
</Box>


              {/* Sección de BCP */}
              <Box
  sx={{
    textAlign: "left",
    mb: 3,
    p: 2,
    border: "1px solid #e0e0e0",
    borderRadius: 2,
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Asegura que el botón quede alineado
    transition: "all 0.3s",
    "&:hover": { boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)" },
  }}
>
  <Box>
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
      Transferencia Bancaria
    </Typography>
    <Typography variant="body2" sx={{ color: "#555" }}>
      Nº de cuenta Soles: <strong>47597235559093</strong>
      <Button
    onClick={() => copyToClipboard("47597235559093")}
    sx={{ minWidth: "auto", paddingRight:'2px' }}
  >
    <ContentCopy fontSize="small" />
  </Button>
    </Typography>
    
    <Typography variant="body2" sx={{ color: "#555" }}>
      Nº de cuenta interbancaria: <strong>00247519723555909325</strong>
      <Button
    onClick={() => copyToClipboard("00247519723555909325")}
    sx={{ minWidth: "auto",  paddingRight:'2px' }}
  >
    <ContentCopy fontSize="small" />
  </Button>
    </Typography>
    
    <Typography variant="body2" sx={{ color: "#555" }}>
      Banco: <strong>Banco de Crédito del Perú (BCP)</strong>
    </Typography>
    <Typography variant="body2" sx={{ color: "#555" }}>
      Titular: <strong>ROBERTO IGNACIO SALAZAR GARCIA</strong>
    </Typography>
  </Box>
</Box>


              {/* Subida de Comprobante */}
              <Typography variant="body1" sx={{ color: "#333", fontWeight: "bold", mb: 2 }}>
                Adjunta una captura de tu comprobante:
              </Typography>

              <Box
                sx={{
                  border: "2px dashed #3f51b5",
                  borderRadius: 2,
                  p: 2,
                  mb: 2,
                  backgroundColor: "#e3f2fd",
                  textAlign: "center",
                  transition: "all 0.3s",
                  "&:hover": { backgroundColor: "#bbdefb" },
                }}
              >
                <UploadFile sx={{ fontSize: 40, color: "#3f51b5", mb: 1 }} />
                <Button
                  variant="outlined"
                  component="label"
                  fullWidth
                  sx={{
                    mb: 2,
                    borderColor: uploading ? "#ff9800" : "#3f51b5",
                    color: uploading ? "#ff9800" : "#3f51b5",
                    fontWeight: "bold",
                  }}
                >
                  {uploading ? "Subiendo..." : "Subir Comprobante"}
                  <input type="file" accept="image/*" hidden onChange={handleFileUpload} />
                </Button>
              </Box>

              {/* Mensajes de carga y confirmación */}
              {uploading && (
                <Box sx={{ my: 2 }}>
                  <CircularProgress size={30} sx={{ color: "#ff9800" }} />
                </Box>
              )}

              {fileUploaded && (
                <Fade in={fileUploaded}>
                  <Box sx={{ textAlign: "center", my: 2 }}>
                    <Typography variant="body2" sx={{ color: "green", fontWeight: "bold" }}>
                      ¡Comprobante subido con éxito!
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#555" }}>
                      Archivo: {fileName}
                    </Typography>
                  </Box>
                </Fade>
              )}
            </>
          ) : (
            // Si el número no es de Perú
            <Typography variant="body1" sx={{ color: "#555", textAlign: "center", fontWeight: "bold" }}>
              El número proporcionado no es de Perú. Nos contactaremos contigo al número proporcionado.
            </Typography>
          )}

          {/* Mensaje de error */}
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}

          {/* Botones de acción */}
          <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
            <Button variant="outlined" color="secondary" fullWidth onClick={onClose} sx={{ fontWeight: "bold" }}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleConfirm}
              disabled={confirming}
              sx={{
                fontWeight: "bold",
                backgroundColor: confirming ? "#e0e0e0" : "#4caf50",
                color: confirming ? "#212121" : "#ffffff",
                "&:hover": { backgroundColor: confirming ? "#bdbdbd" : "#388e3c" },
              }}
            >
              {confirming ? <CircularProgress size={24} sx={{ color: "#ffffff" }} /> : "Confirmar y Finalizar"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default PaymentStep;
