import axios from 'axios';

// Configuración de Cloudinary (asegúrate de reemplazar con tus credenciales)
const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dxovxynjc/image/upload';
const CLOUDINARY_UPLOAD_PRESET = 'djponla';

export const uploadFileToCloudinary = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

  try {
    const response = await axios.post(CLOUDINARY_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Devuelve la URL del archivo subido
    return response.data.secure_url;
  } catch (error) {
    console.error('Error al subir el archivo a Cloudinary', error);
    throw new Error('Error al subir el archivo');
  }
};
